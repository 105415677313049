// require("../main");

window.addEventListener("DOMContentLoaded", () => {
    // --------------- Login --------------- //
    function show() {
        const p = document.getElementById("pwd");
        if (p) {
            p.setAttribute("type", "text");
        }
    }

    function hide() {
        const p = document.getElementById("pwd");
        if (p) {
            p.setAttribute("type", "password");
        }
    }

    let pwShown = 0;

    const eyeIcon = document.querySelector(".eye");
    if (eyeIcon !== null) {
        eyeIcon.addEventListener("click", function () {
            if (pwShown === 0) {
                pwShown = 1;
                show();
            } else {
                pwShown = 0;
                hide();
            }

            // Additionally, you can change the icon class for visual notation
            if (eyeIcon.classList.contains("crossed-out")) {
                eyeIcon.classList.remove("crossed-out");
            } else {
                eyeIcon.classList.add("crossed-out");
            }
        });
    }

    // --------------- Modal --------------- //
    // Open Modal 1
    const openModalBtn1 = document.getElementById("openModalBtn1");
    if (openModalBtn1 !== null) {
        openModalBtn1.addEventListener("click", function (e) {
            e.preventDefault();
            const modal1 = document.getElementById("modal1");
            if (modal1 !== null) {
                modal1.style.display = "flex";
            }
        });
    }

    // Open Modal 2
    const openModalBtn2 = document.getElementById("openModalBtn2");
    if (openModalBtn2 !== null) {
        openModalBtn2.addEventListener("click", function (e) {
            e.preventDefault();
            const modal2 = document.getElementById("modal2");
            if (modal2 !== null) {
                modal2.style.display = "flex";
            }
        });
    }

    // Close Modals
    const closeBtns = document.getElementsByClassName("login__close");
    for (let i = 0; i < closeBtns.length; i++) {
        closeBtns[i].addEventListener("click", function () {
            let modal = this.parentElement.parentElement;
            if (modal !== null) {
                modal.style.display = "none";
            }
        });
    }

    // Close Modals on outside click
    window.addEventListener("click", function (event) {
        const modals = document.getElementsByClassName("login__modal");
        for (let i = 0; i < modals.length; i++) {
            if (event.target == modals[i] && modals[i] !== null) {
                modals[i].style.display = "none";
            }
        }
    });
});
